import activity from './m-write-blog.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Write Blog',
  icon: 'mdi-feather',
  description: 'Write a blog story about your experiences for the sponsor to promote!!',
  status: true,
  styling: {
    borderColor: '#3c9dcd'
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-write-blog',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        airtableLink: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
